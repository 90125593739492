<template>
  <keep-alive :exclude="/Item/">
    <router-view />
  </keep-alive>
</template>

<script>
// Пустой компонент для пробрасывания дочерних сущностей в router

export default {};
</script>
